import React from "react";

// assets
import recruitment_img from "../assets/images/Campus-Recruitment-Logos_Engineering.png";

const CampusRecruitment = () => {
  return (
    <>
      <p className="fa-2x text-center">
        Top Campus <span style={{ color: "#e3000f" }}>Recruiters</span>
      </p>
      <p className="text-center">
        We have strong links with more than 500+ blue chip organisations to give
        valuable real-world <br /> experience that will boost your employment
        prospects.
      </p>
      <img
        src={recruitment_img}
        alt="Campus recruitment"
        className="img-fluid"
      />
    </>
  );
};

export default CampusRecruitment;
