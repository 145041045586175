import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Home showEnquiryForm={true} type="institution" />}
        />
        <Route
          path="/btech"
          element={
            <Home
              showEnquiryForm={true}
              course="Engineering"
              type="engineering"
              courses="Computer Science Engineering | Information Science | Artificial Intelligence & ML | Electronics and Communication | Mechanical Engineering"
            />
          }
        />
        <Route
          path="/bcom"
          element={
            <Home
              showEnquiryForm={true}
              course="B.Com"
              type="degree"
              courses="ACCA | Logistics & Supply Chain Management | Forensic Accounting | SAP | "
            />
          }
        />
        <Route
          path="/bca"
          element={
            <Home
              showEnquiryForm={true}
              course="BCA"
              type="degree"
              courses="Cloud Computing | Artificial Intelligence | Data Analytics | Blockchain"
            />
          }
        />
        <Route
          path="/bba"
          element={
            <Home
              showEnquiryForm={true}
              course="BBA"
              type="degree"
              courses="Aviation | Airport Management | Logistics & Supply chain | Data Analytics | Digital Marketing"
            />
          }
        />
		
		  <Route
          path="/degree"
          element={
            <Home
              showEnquiryForm={true}
              course="Degree"
              type="degree"
              courses="Aviation | Airport Management | Logistics & Supply chain | Data Analytics | Digital Marketing"
            />
          }
        />
        <Route path="/thankyou" element={<Home showEnquiryForm={false} />} />
      </Routes>
    </Router>
  );
}
