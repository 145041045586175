import React from "react";
import { Container, Row, Col } from "reactstrap";
import logo from "../assets/images/HKBK Group.png";
import engineering from "../assets/images/engineering.jpg";
import degree from "../assets/images/degree.jpg";
import school_of_business from "../assets/images/school-of-business.jpg";
import life_science from "../assets/images/life-science.jpg";
import athletics from "../assets/images/athletics.jpg";
import summer_internships from "../assets/images/summer-internships.jpg";
import student_life from "../assets/images/student-life.jpg";
import telecaller from "../assets/images/telecaller.png";
import placements_img from "../assets/images/placements.png";
import ibm from "../assets/images/ibm.png";
import microsoft_logo from "../assets/images/microsoft-logo.png";

import CountUp from "react-countup";
import RankingsRecognition from "./RankingsRecognition";
import TypeAnimation from "react-type-animation";
import Marquee from "react-fast-marquee";
import "../App.css";
import Advantage from "./Advantage";
import CampusRecruitment from "./CampusRecruitment";

const tapToTop = () => {
  window.scrollTo({
    behavior: "smooth",
    top: 0
  });
};

const handleScroll = () => {
  let number =
    window.pageXOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;
  let divs = document.querySelectorAll(".sticky-col");
  if (number >= 300) {
    if (window.innerWidth < 576) {
      document.getElementById("sticky").classList.remove("fixed");
      for (var i = 0; i < divs.length; i++) {
        divs[i].classList.add("col-md-4");
        divs[i].classList.remove("col-md-3");
      }
      document.getElementById("apply-now-on-sticky").style.display = "none";
    } else {
      document.getElementById("sticky").classList.add("fixed");
      for (var i = 0; i < divs.length; i++) {
        divs[i].classList.add("col-md-3");
        divs[i].classList.remove("col-md-4");
      }
      document.getElementById("apply-now-on-sticky").style.display = "block";
    }
  } else {
    document.getElementById("sticky").classList.remove("fixed");
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add("col-md-4");
      divs[i].classList.remove("col-md-3");
    }
    document.getElementById("apply-now-on-sticky").style.display = "none";
  }
};
window.addEventListener("scroll", handleScroll);

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://widgets.in5.nopaperforms.com/emwgts.js";
    s.id = "nopaperforms_widget";
    if (document.getElementById("nopaperforms_widget")) {
      document.getElementById("nopaperforms_widget").remove();
    }
    document.body.appendChild(s);
  }

  render() {
    return (
      <div>
        <header className={`sticky`}>
          <div className={`top-header`}>
            <Container>
              <Row>
                <Col lg="3" md="3">
                  <div className="header-contact">
                    <img
                      src={logo}
                      alt="HKBK Group Logo"
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col
                  lg="5"
                  md="5"
                  className="align-items-center d-flex justify-content-center my-2"
                >
                  <span className="h-100 align-items-center d-flex justify-content-center text-white bg-primary-1 p-2">
                    Admissions Open for
                  </span>
                  <Marquee gradient={false}>
                    Engineering - Computer Science / Artificial Intelligence and
                    ML / Information Science / Electronics and Communication /
                    Mechanical. Degree - B.Com / B.B.A / B.C.A. Health Care -
                    Physiotherapy (BPT) / BSc. MLT / BSc. MIT
                  </Marquee>
                </Col>
                {/* <Col lg="4" md="8" className="header-dropdown">
                  India No. -
                  <a href="tel:9035522311" className="text-black">
                    9035522311
                  </a>
                  <br />
                  For International Queries Call.
                  <a href="tel:+919035022250" className="text-black">
                    +91 9035022250
                  </a>
                </Col> */}
              </Row>
            </Container>
          </div>
        </header>
        <div
          className={`home text-center`}
          style={{
            backgroundImage:
              this.props.type === "degree"
                ? "url(https://hkbk.edu.in/backend/backend/DegreeLP.jpg)"
                : ""
          }}
        >
          <Container>
            <Row>
              
              <Col md="5" lg="7" xl="8">
                <div className="slider-contain text-left">
                  <div>
                    {/* {this.props.type === "engineering" ? (
                      <div>
                        <h1 className="text-transform-initial fa-2x text-black">
                          B.Tech Admissions {new Date().getFullYear()}
                        </h1>
                        <h6 className="border border-info p-2 rounded text-black font-weight-bold w-50">
                          {this.props.courses}
                        </h6>
                      </div>
                    ) : this.props.type === "degree" ? (
                      <div>
                        <h1 className="text-transform-initial fa-2x text-black">
                          {this.props.course} Admissions
                          {new Date().getFullYear()}
                        </h1>
                        <h6 className="border border-info p-2 rounded text-black font-weight-bold w-50">
                          {this.props.courses}
                        </h6>
                      </div>
                    ) : ( */}
                      <h1 className="text-transform-initial fa-3x text-black">
                        Gain the skills <br />
                        that matter in the <br />
                        real world
                      </h1>
                    {/* )} */}
                    <div
                      style={{ minHeight: "2.5em" }}
                      className="typing-animation d-flex"
                    >
                      <h3 className="bg-black mr-1 px-1 mb-0">
                        Admissions open
                      </h3>
                      <TypeAnimation
                        cursor={false}
                        sequence={["Apply now", 1500, "|"]}
                        wrapper="h3"
                        repeat={Infinity}
                        className="text-black font-weight-bold mb-0"
                      />
                    </div>
                    <div className="mt-3 w-50">
                      <h5>In association with</h5>
                      <img
                        src={ibm}
                        alt="In Asociation with IBM"
                        className="img-fluid w-25 mb-2"
                      />
                      <br />
                      <img
                        src={microsoft_logo}
                        alt="In Association with Microsoft"
                        className="img-fluid w-50"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {this.props.showEnquiryForm ? (
                <>
                  {this.props.type === "institution" && (
                    <Col md="7" lg="5" xl="4" className="my-md-3">
                      <h5 className="bg-white mb-0 pt-1">
                        Admissions Open {new Date().getFullYear()}
                      </h5>
                      <div
                        className="npf_wgts"
                        data-height="490px"
                        data-w="b2606e41c73e7f5b4e358bfb64b46fb8"
                      ></div>
                    </Col>
                  )}
                  {this.props.type === "engineering" && (
                    <Col md="7" lg="5" xl="4" className="my-md-3">
                      <h5 className="bg-white mb-0 pt-1">
                        {this.props.course} Admissions Open{" "}
                        {new Date().getFullYear()}
                      </h5>
                      <div
                        className="npf_wgts"
                        data-height="490px"
                        data-w="e00317c0c063a6946e3e045f85cd8444"
                      ></div>
                    </Col>
                  )}
                  {this.props.type === "degree" && (
                    <Col md="7" lg="5" xl="4" className="my-md-3">
                      <h5 className="bg-white mb-0 pt-1">
                        {this.props.course} Admissions Open{" "}
                        {new Date().getFullYear()}
                      </h5>
                      <div
                        className="npf_wgts"
                        data-height="490px"
                        data-w="5d44ff7f03742462fbe0c096f785baa1"
                      ></div>
                    </Col>
                  )}
                </>
              ) : (
                <Col md="7" lg="5" xl="4" className="my-md-3 text-left">
                  <div className="bg-black p-2 text-white">
                    <h3>Thank You!</h3>
                    <p className="text-white">
                     Your Enquiry form has been submitted successfully. A verification email has been sent to your registered email ID.You can login with details provided in your Email.
					
                    </p>
					 <a className="btn btn-solid" href="https://apply.hkbk.edu.in">
                   Click here to Login
                  </a>
					
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </div>
        <section className="py-3 Gainsboro sticky" id="sticky">
          <Container>
            <Row id="sticky-section">
              <Col md="4" className="sticky-col">
                <Row className="justify-content-center py-1">
                  <p className="fa-3x Pumpkin mb-0  font-weight-bold">
                    #
                    <CountUp start={0} end={7} delay={0} duration={5}>
                      {({ countUpRef }) => <span ref={countUpRef} />}
                    </CountUp>
                  </p>
                  <p className="fa-lg text-black mb-0">
                    among Top 10
                    <br /> Bangalore
                  </p>
                </Row>
              </Col>
              <Col md="4" className="sticky-col">
                <Row className="justify-content-center py-1">
                  <p className="fa-3x Pumpkin mb-0  font-weight-bold">
                    <CountUp start={0} end={93} delay={0} duration={5}>
                      {({ countUpRef }) => <span ref={countUpRef} />}
                    </CountUp>
                    %
                  </p>
                  <p className="fa-lg text-black mb-0">
                    Placement in
                    <br /> top MNC's
                  </p>
                </Row>
              </Col>
              <Col md="4" className="sticky-col">
                <Row className="justify-content-center py-1">
                  <p className="fa-3x Pumpkin mb-0  font-weight-bold">
                    <CountUp start={0} end={14} delay={0} duration={5}>
                      {({ countUpRef }) => <span ref={countUpRef} />}
                    </CountUp>
                  </p>
                  <p className="fa-lg text-black mt-2 mb-0">
                    acre WI-FI Campus
                  </p>
                </Row>
              </Col>
              <Col md="3" id="apply-now-on-sticky">
                <Row className="justify-content-center py-1">
                  <a className="btn btn-solid" onClick={tapToTop}>
                    Apply Now
                  </a>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-4">
          <Container>
            <Row>
              <Col>
                <h1 className="mb-3 fa-3x text-transform-initial font-weight-normal">
                  Apply to HKBK Institutions
                </h1>
                <Row>
                  <Col
                    className=" px-4 py-3 "
                    style={{ border: "1px solid #f85007" }}
                  >
                    <h2 className="fa-2x text-transform-initial font-weight-normal">
                      Apply Now!
                    </h2>
                    <p>
                      Applications are being accepted for 2022-23 academic year.
                      Scholarships are giving to girl students. Students may
                      also apply for competitive scholarships for government.
                      Official Entrance exam scores are required for merit
                      scholarship consideration from Institute.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-4">
          <Container>
            <h3 className="text-center text-black fa-2x font-weight-bold mb-4">
              HKBK ADVANTAGE
            </h3>
            <Advantage />
          </Container>
        </section>
        <section className="py-4 courses-section">
          <Container>
            <Row>
              <Col md="3" className=" my-2">
                <img src={engineering} alt="" className="img-fluid" />
                <ul>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Computer Science
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Artificial
                    Intelligence and ML learning
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Information Science
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Electronics and
                    Communication
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Mechanical
                  </li>
                </ul>
              </Col>
              <Col md="3" className=" my-2">
                <img src={degree} alt="" className="img-fluid" />
                <ul>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> BBA - Aviation
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.Com + Forensic
                    Accounting
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.B.A + Logistics &
                    Supply Chain
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.C.A + Cloud
                    Computing
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.C.A + Artificial
                    Intelligence
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.Sc - CS Cyber
                    Forensic
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.Com
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> B.Com + Logistics in
                    Supply Chain
                  </li>
                </ul>
              </Col>
              <Col md="3" className=" my-2">
                <img src={school_of_business} alt="" className="img-fluid" />
                <ul>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> MBA - Advanced
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> MBA - Global
                  </li>
                </ul>
              </Col>
              <Col md="3" className=" my-2">
                <img src={life_science} alt="" className="img-fluid" />
                <ul>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> Physiotherapy (BPT)
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> BSc. MLT
                  </li>
                  <li className="font-weight-bold">
                    <i className="fa fa-arrow-right"></i> BSc. MIT
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        {this.props.type === "engineering" && (
          <section className="py-1">
            <Container className="p-md-0">
              <img
                src="https://hkbk.edu.in/backend/backend/Addons.png"
                alt="Courses"
                className="img-fluid"
              />
            </Container>
          </section>
        )}
        <section className="py-3">
          <Container className="p-md-0">
            <h3 className="text-center text-black fa-2x font-weight-bold mb-4 text-transform-initial">
              Ranking and Recognitions
            </h3>
            <RankingsRecognition />
          </Container>
        </section>
        <section className="py-4 bg-white-smoke why-hkbk">
          <Container>
            <Row>
              <Col md="12">
                <h3 className="text-center text-black fa-2x font-weight-bold mb-4">
                  WHY HKBK
                </h3>
              </Col>
              <Col md="12">
                <Row>
                  <Col
                    md="4"
                    className="text-center border-bottom classic-effect"
                  >
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">94%</p>
                    <p className="fa-lg text-black">Placement* record</p>
                    <p className="fa-lg text-black">
                      Placed in MNC's in India and international
                    </p>
                    <span></span>
                  </Col>
                  <Col
                    md="4"
                    className="text-center border-right border-left  border-bottom classic-effect"
                  >
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">#7</p>
                    <p className="fa-lg text-black">
                      Ranked Engineering college
                      <br /> in Bangalore
                    </p>
                    <p className="fa-lg text-black">Mentioned by TOI, Forbis</p>
                    <span></span>
                  </Col>
                  <Col
                    md="4"
                    className="text-center  border-bottom classic-effect"
                  >
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">20K+</p>
                    <p className="fa-lg text-black">
                      Alumini across
                      <br /> the Globe
                    </p>
                    <span></span>
                  </Col>
                  <Col md="4" className="text-center classic-effect">
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">200+</p>
                    <p className="fa-lg text-black">
                      Highly Skilled
                      <br /> Faculty
                    </p>
                    <p className="fa-lg text-black">
                      Multi-disciplinary and Holistic Learning
                    </p>
                    <span></span>
                  </Col>
                  <Col
                    md="4"
                    className="text-center border-right border-left classic-effect"
                  >
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">300+</p>
                    <p className="fa-lg text-black">Prominent Recruiters</p>
                    <p className="fa-lg text-black">
                      Unmatched Global Opportunities
                    </p>
                    <span></span>
                  </Col>
                  <Col md="4" className="text-center classic-effect">
                    <p className="fa-3x Pumpkin mb-0 font-weight-bold">14+</p>
                    <p className="fa-lg text-black">
                      Acres of Lush
                      <br /> Green Campus
                    </p>
                    <span></span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-4">
          <Container>
            <CampusRecruitment />
          </Container>
        </section>
        <section className="py-4 life-at-hkbk">
          <Container>
            <Row>
              <Col md="12">
                <h3 className="text-center text-black fa-2x font-weight-bold mb-4 text-transform-initial">
                  Life at HKBK
                </h3>
              </Col>
              <Col md="4 mt-2">
                <img src={athletics} alt="" className="img-fluid" />
                <h4 className="text-black font-weight-bold fa-lg mt-3">
                  Athletics
                </h4>
                <p>
                  Our athletics department is committed to building champions
                  through academic achievement, athletic excellence, and
                  national recognition of students-athletics, teams, and
                  programs.
                </p>
                <a className="btn btn-solid rounded-0" onClick={tapToTop}>
                  Learn More
                </a>
              </Col>
              <Col md="4 mt-2">
                <img src={summer_internships} alt="" className="img-fluid" />
                <h4 className="text-black font-weight-bold fa-lg mt-3">
                  Summer Internships
                </h4>
                <p>
                  Collaborated with many reputed organizations for Summer
                  internships for students and exposure to real life projects.
                </p>
                <a className="btn btn-solid rounded-0" onClick={tapToTop}>
                  Learn More
                </a>
              </Col>
              <Col md="4 mt-2">
                <img src={student_life} alt="" className="img-fluid" />
                <h4 className="text-black font-weight-bold fa-lg mt-3">
                  Student Life
                </h4>
                <p>
                  Best Campus life with best resedential facilities, Food
                  courts, Gym and sports facilities.
                </p>
                <a className="btn btn-solid rounded-0" onClick={tapToTop}>
                  Learn More
                </a>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-4 Camelot">
          <Container>
            <Row>
              <Col className="text-center">
                <img src={placements_img} alt="" className="img-fluid" />
                <p
                  className="text-white mt-4 mb-1"
                  style={{ fontSize: "x-large" }}
                >
                  Early registrations are encouraged. Seats fill up quickly!
                </p>
                <a
                  className="btn btn-lg btn-outline-light rounded-0 mt-3"
                  onClick={tapToTop}
                >
                  APPLY NOW
                </a>
                {/* <p className="text-white fa-2x">Flexible payment options available. Learn more.</p> */}
              </Col>
            </Row>
          </Container>
        </section>
{/* 
      <section className="py-4">
          <Container>
            <Row>
              <Col md="4" className="px-3 py-3  bg-white-smoke">
                <Row>
                  <img
                    src={telecaller}
                    alt=""
                    className="img-fluid rounded-circle border"
                  />
                  <p className="fa-3x Regal-Blue mt-5 font-weight-bold">
                    Help Desk
                  </p>
                </Row>
              </Col>
              <Col
                md="8"
                className="bg-salomie d-flex flex-column help-desk-info justify-content-center px-3 py-3"
              >
                <p className="text-black" style={{ fontSize: "x-large" }}>
                  Helpdesk:
                  <a href="tel:+919035522311" className="text-black">
                    +91 9035522311
                  </a>
                  /
                  <a href="tel:+919035022250" className="text-black">
                    9035022250
                  </a>
                </p>
               <p className="text-black fa-2x">Monday - Saturday (except public holidays) 9:00 AM to 5:00 PM</p> 
                <p className="text-black" style={{ fontSize: "x-large" }}>
                  Email:
                  <a
                    href="mailto:admissions@hkbk.edu.in"
                    className="text-black"
                  >
                    admissions@hkbk.edu.in
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>
    );
  }
}
// export default App;
