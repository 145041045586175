import React from "react";

import Slider from "react-slick";

import advantage_reserch from "../assets/images/advantage_reserch.jpg";

import industrial_logo1 from "../assets/images/industrial-logo1.png";
import industrial_logo2 from "../assets/images/industrial-logo2.png";
import industrial_logo3 from "../assets/images/industrial-logo3.png";
import industrial_logo4 from "../assets/images/industrial-logo4.png";

import google_logo from "../assets/images/google_logo.jpg";

import tie_upicon from "../assets/images/tie-upicon.png";

import nba_logo from "../assets/images/advantage-nba-logo.png";

const Advantage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="advantage-section">
      <div>
        <div className="advantage-slide-item">
          <h6 className="bg-primary-1">RESEARCH</h6>
          <img src={advantage_reserch} alt="" className="img-fluid m-auto" />
          <p
            style={{ background: "rgba(0,0,0,.5)" }}
            className="text-white text-center"
          >
            Teaching is more than a profession, for the faculty members of
            HKBKCE, it is a passion
          </p>
        </div>
      </div>
      <div>
        <div className="advantage-slide-item">
          <h6 className="bg-black">INDUSTRIAL TIE-UPS</h6>
          <img
            src={industrial_logo1}
            alt=""
            className="img-fluid my-2 mx-auto"
          />
          <hr className="m-0" />
          <img
            src={industrial_logo2}
            alt=""
            className="img-fluid my-2 mx-auto"
          />
          <hr className="m-0" />
          <img
            src={industrial_logo3}
            alt=""
            className="img-fluid my-2 mx-auto"
          />
          <hr className="m-0" />
          <img
            src={industrial_logo4}
            alt=""
            className="img-fluid my-2 mx-auto"
          />
        </div>
      </div>
      <div>
        <div className="advantage-slide-item">
          <h6 className="bg-primary-1">COLLABORATED WITH GOOGLE</h6>
          <img src={google_logo} alt="" className="img-fluid m-auto py-4" />
          <p
            style={{ background: "rgba(0,0,0,.5)" }}
            className="text-white text-center"
          >
            Ensuring Strong Bonding with Industry via Instituional Alliance
          </p>
        </div>
      </div>
      <div>
        <div className="advantage-slide-item">
          <h6 className="bg-black mb-4">INTERNATIONAL TIE-UPS</h6>
          <img src={tie_upicon} alt="" className="img-fluid m-auto pb-5" />
          <p className="text-center mb-n2">
            American University in Bosnia & Herzegovina
          </p>
        </div>
      </div>
      <div>
        <div className="advantage-slide-item">
          <h6 className="bg-primary-1">NATIONAL RECOGNITION</h6>
          <img src={nba_logo} alt="" className="img-fluid m-auto pt-2 pb-5" />
          <p
            style={{ background: "rgba(0,0,0,.5)" }}
            className="text-white text-center mb-n3"
          >
            Accrediated by National Board of accreditation (NBA) & NAAC
          </p>
        </div>
      </div>
    </Slider>
  );
};

export default Advantage;
