import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import outlook_img from "../assets/images/outlook-logo.png";
import career360_img from "../assets/images/carrer-360.png";
import nba_img from "../assets/images/nba-logo.png";
import forbis_img from "../assets/images/Forbes.png";
import silicon_india_img from "../assets/images/SiliconIndia.png";

const RankingsRecognition = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Ranked</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              <sup>#</sup>34
            </h1>
            <h6>among top 100 Engineering colleges in India</h6>
            <img src={outlook_img} alt="" className="img-fluid mt-4" />
          </div>
        </div>
      </div>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Rated</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              AAA<sup>+</sup>
            </h1>
            <h6>by India's only education magazine Careers 360</h6>
            <img src={career360_img} alt="" className="img-fluid mt-4 mb-2" />
          </div>
        </div>
      </div>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Exposure to</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              Next-Gen tech
            </h1>
            <h6 className="my-4">via industry packed courses</h6>
          </div>
        </div>
      </div>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Featured</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              Best
            </h1>
            <h6>Among the great Indian Institute's of the year</h6>
            <img src={forbis_img} alt="" className="img-fluid mt-2 ml-n3" />
          </div>
        </div>
      </div>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Ranked</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              <sup>#</sup>7
            </h1>
            <h6>among Engineering Colleges in Bangalore</h6>
            <img
              src={silicon_india_img}
              alt=""
              className="img-fluid mt-4 ml-n3"
            />
          </div>
        </div>
      </div>
      <div className="w-100 px-3 h-100">
        <div className="card">
          <div className="card-body">
            <h5>Impeccadle</h5>
            <h1 className="my-3 font-weight-bold text-secondary-1 line-height-1">
              Placements
            </h1>
            <h6 className="mb-5 pb-2">
              assured for eligible candidates in fortune 500 companies
            </h6>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default RankingsRecognition;
